export const externalAdLibs = {

    test: function () {
        import(/*webpackIgnore: true*/ 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.10.3/jquery-ui.min.js');
    },

    loadDidomi: function () { 
        if (!window.didomiConfig) window.didomiConfig = {};
        if (!window.didomiConfig.notice) window.didomiConfig.notice = {};
        if (!window.didomiConfig.app) window.didomiConfig.app = {};
        // time in seconds pay to reject should be valid. In this example, one day in seconds is 86400.
        window.didomiConfig.app.deniedConsentDuration = 86400;
        window.didomiConfig.notice.enable = false;
        console.log('Loading Didomi ... with this didomiConfig: ', window.didomiConfig);
        window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
        else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
        var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
        if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
        var t=document.createElement("script");t.id="spcloader";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("137c2d1b-0c29-4419-99a9-15a7854da8c3")})();
        return true;
    },

    loadGtm: function(container = 'GTM-P6RVQZ') {
        console.log(`load gtm script container: ${container}`);
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtmjs'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',container);
    },

    loadBluemedia: function () {
        console.log('bluemedia call');
        !function(){window.hb_now=Date.now();var e=document.getElementsByTagName("script")[0],t="https://hb.20m.es/stable/config/gruposerra.com/desktop-mobile.min.js";window.location.search.match(/hb_test=true/)&&(t=t.replace("/stable/","/test/"));var n=document.createElement("script");n.async=!0,n.src=t,e.parentNode.insertBefore(n,e)}();
    },

    loadYieldLove: function (siteDomain) {
        window.yieldlove_prevent_autostart = true;

        console.log('yieldLove call');
        import(/*webpackIgnore: true*/ `https://cdn-a.yieldlove.com/v2/yieldlove.js?${siteDomain}`)
            .then((el) => {
                console.log(`yieldLove loaded`);
            });
    },

    initGPT: function () {
        console.log('init Google Publisher Tags');
        let destNode = document.querySelectorAll('script')[0];
        let gpt = document.createElement('script');
        gpt.async = true;
        gpt.src = '//www.googletagservices.com/tag/js/gpt.js';

        return destNode?.parentNode.insertBefore(gpt, destNode);
    },

    loadGtag: function () {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',tagmanagerContainer);
        return true;
    },

    loadTaboola: function (publisher_id) {
        console.log(`loadTaboola id: ${publisher_id}`);
        window._taboola = window._taboola || [];
        _taboola.push({article:'auto'});
        !function (e, f, u, i) {
            if (!document.getElementById(i)){e.async = 1;e.src = u;e.id = i;f.parentNode.insertBefore(e, f);}
        }(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/gruposerranetwork-'+publisher_id+'/loader.js', 'tb_loader_script');
        if (window.performance && typeof window.performance.mark == 'function') {
            window.performance.mark('tbl_ic');
        }
        window._taboola = window._taboola || [];
        _taboola.push({
            mode: 'thumbnails-a',
            container: 'taboola-below-article-thumbnails',
            placement: 'Below Article Thumbnails',
            target_type: 'mix'
        });
        _taboola.push({flush: true});
    },

    loadTeads: function (publisher_id, cb) {
        console.log(`queuing teads id: ${publisher_id}`);
        if (typeof window.gdprAppliesGlobally!=='undefined')
        {
          //Carga segun Didomi
          window.didomiOnReady = window.didomiOnReady || [];
          window.didomiOnReady.push(function (Didomi) {
            let s = document.createElement("script"), el = document.getElementsByTagName("script")[0];
            s.addEventListener("load", (e) => typeof cb === "function" ? cb() : false);
            s.async = true;
            s.src = "//a.teads.tv/page/"+publisher_id+"/tag";
            el.parentNode.insertBefore(s, el);
          });
        }
        else
        {
          //Carga segun OpenCMP
          let s = document.createElement("script"), el = document.getElementsByTagName("script")[0];
          s.type = "text/plain";
          s.setAttribute('data-cmp-vendor', '132');
          s.setAttribute('data-cmp-script', null);
          s.addEventListener("load", (e) => typeof cb === "function" ? cb() : false);
          s.async = true;
          s.src = "//a.teads.tv/page/"+publisher_id+"/tag";
          el.parentNode.insertBefore(s, el);
        }
    },

    loadSeedtag: function (publisher_id) {
        console.log(`loadSeedtag id: ${publisher_id}`);

        let destNode = document.querySelector('script');
        let seedtag = document.createElement('script');

        seedtag.async = true;
        seedtag.src = `//t.seedtag.com/t/${publisher_id}.js`;

        return destNode?.parentNode.insertBefore(seedtag, destNode);
    },
}
