'use strict';

export function setPageViews(numpages = 10, tag = 'metered_wall') {
    let prct = JSON.parse(localStorage.getItem('_pr_ct'));

    prct.vtd.filter(item => {
        item.views = item.tag == tag ? numpages : item.views;
    });

    localStorage.setItem('_pr_ct', JSON.stringify(prct));
    indexedDB.deleteDatabase('PrometeoDatabase');

    console.log(`Pageview tag ${tag} set to ${numpages} pages`);

    setTimeout(() => {
        location.reload();
    }, 500);
}

export function getPageViews(tag = 'metered_wall') {
    let pageviews = JSON.parse(localStorage.getItem('_pr_ct')).vtd.filter(item => {
        return item.tag == tag;
    });

    console.log(`Pageview counter is ${pageviews[0].views} for tag ${tag}`);
}