'use strict';

const handleImgIframe = (content, $modal, $inner, type) => {
    const el = document.createElement(type);
    el.src = content;
    $inner.append(el);
    el.addEventListener('load', () => $modal.modal('show'));
};

const handleContent = (content, $modal, $inner) => {
    $inner.append(content);
    $modal.modal('show');
};

export function registerModalImg($e, render_width, threshold = 15) {
    const modal_src = $e.data('full-src') || $e.attr('src') || false;

    const full_width = $e.data('full-dimensions')
        ? $e.data('full-dimensions').width
        : $e.prop('naturalWidth');

    const up_ratio = render_width
        ? Math.ceil((full_width * 100) / render_width - 100)
        : threshold;

    console.log(
        $e,
        `rwidth: ${render_width}, fwidth: ${full_width}, ratio: ${up_ratio}%`
    );

    if (up_ratio >= threshold) {
        $e.addClass('show-modal');
        $e.on('click', () => openModal(modal_src, 'img'));
    }
}

export function closeModal(modal_id = '#base-modal') {
    const $modal = $(modal_id);
    $modal.modal('hide');
}

export function openModal(content, type = false, modal_id = '#base-modal') {
    const $modal = $(modal_id);

    if (!content) {
        console.error('no modal src found!');
        return false;
    }

    if (!$modal.length) {
        console.error(`no modal with id ${modal_id} found`);
        return false;
    }

    const $inner = $('.modal-body .modal-inner', modal_id);

    $inner.html('');

    switch (type) {
        case 'img':
            handleImgIframe(content, $modal, $inner, 'img');
            break;
        case 'iframe':
            handleImgIframe(content, $modal, $inner, 'iframe');
            break;
        default:
            handleContent(content, $modal, $inner);
            break;
    }
}
