'use strict';

import {WindowSize} from '../lib/windowsize.js';

const ws = new WindowSize();

export function skyscraper_height() {
    $('.skys').css('height', $('main').css('height'));
}

function recalcSkyScraper() {
    let scrollPosition = parseInt(window.pageYOffset / 100);

    if (scrollPosition % 20 === 0) {
        skyscraper_height();
    }
}

function handleRecalcSkyScraper() {
    skyscraper_height();
    window.addEventListener("scroll", () => recalcSkyScraper());
}

ws.listen_desktop(handleRecalcSkyScraper);

$(() => {
    if (ws.is_desktop()) {
        handleRecalcSkyScraper();
    }
});
