'use strict';

export class WindowSize {


    constructor() {
        this.mobile_pref = matchMedia("only screen and (max-width: 768px)");
        this.tablet_pref = matchMedia("only screen and (min-width: 768px) and (max-width: 991px)");
        this.desktop_pref = matchMedia("only screen and (min-width: 991px)");
    }

    listen_mobile(cb_matches, cb_not_matches = null, params_matches = {}, params_not_matches = {}) {
        this.mobile_pref.addEventListener("change",mql => this._do(mql, cb_matches, cb_not_matches, params_matches, params_not_matches));
    }

    listen_tablet(cb_matches, cb_not_matches = null, params_matches = {}, params_not_matches = {}) {
        this.tablet_pref.addEventListener("change",mql => this._do(mql, cb_matches, cb_not_matches, params_matches, params_not_matches));
    }

    listen_desktop(cb_matches, cb_not_matches = null, params_matches = {}, params_not_matches = {}) {
        this.desktop_pref.addEventListener("change",mql => this._do(mql, cb_matches, cb_not_matches, params_matches, params_not_matches));
    }

    is_mobile() {
        return this.mobile_pref.matches;
    }

    is_tablet() {
        return this.tablet_pref.matches;
    }

    is_desktop() {
        return this.desktop_pref.matches;
    }

    detect() {
        return this.is_desktop() ? 'desktop' : (this.is_tablet() ? 'tablet' : 'mobile');
    }

    _do(mql, cb_matches, cb_not_matches, params_matches, params_not_matches) {

        // Avoid double event firing
        if (this.lastMatch == this.detect()) {
            // console.log('doubleee ', this.lastMatch, this.detect());
            return false;
        }

        this.lastMatch = this.detect()

        if (mql.matches || cb_not_matches === null) {
            return cb_matches(params_matches);
        } else if (cb_not_matches) {
            return cb_not_matches(params_not_matches);
        }
    }
}
