'use strict';

import {Cookie} from '../lib/cookie';
import {Ads} from '../lib/ads';
import {setPageViews, getPageViews} from './trigger';
import {fadeParagraph} from './effects';
import {handleIntersect} from './intersection';

globalThis.cookie = new Cookie();
globalThis.Ads = Ads;
globalThis.setPageViews = setPageViews;
globalThis.getPageViews = getPageViews;
globalThis.boxPlusCb = fadeParagraph;
globalThis.handleIntersect = handleIntersect;