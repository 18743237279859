'use strict';

import { WindowSize } from '../../lib/windowsize';
import { Ads } from '../../lib/ads';
import { docReady } from '../../lib/docready';
import { externalAdLibs } from './external';
import { publisher_config, ads_source } from '../../lib/config';
import { Yieldlove } from './yieldlove';
import { Cookie } from '../../lib/cookie';

const ws = new WindowSize();
const ads = new Ads();
const cookie = new Cookie();

class Pubload {
    constructor() {
        console.log('PubLoad:', `using ${ads_source}`);
    }

    bluemedia() {
        dataLayer['0']['platform'] = ws.detect();
        dataLayer.push({ platform: ws.detect() });

        externalAdLibs.loadBluemedia();
        //this.gtag.checkGoogletagSlots();
    }

    yieldLove() {
        if (externalAdLibs.initGPT() === undefined) {
            console.error("Could't initialize Google Publisher Tags.");
            return false;
        }

        this.gtag = new GooggleTag();

        let siteDomain = window.location.href
            .split('/')[2]
            ?.replace(/pre.|www./, '');

        if (siteDomain === undefined) {
            console.error(
                "Could't get siteDomain, not loading yieldLove lib..."
            );
            return false;
        }

        externalAdLibs.loadYieldLove(siteDomain);

        this.gtag.initGoogletag();
        this.gtag.checkGoogletagSlots();

        // TODO: Config siteId, zonePrefix
        let zonePrefix =
            siteDomain === 'majorcadailybulletin.com' ? 'mdb' : 'mm';
        const yl = new Yieldlove(194874792, siteDomain, zonePrefix);
        yl.register();
    }
}

class GooggleTag {
    initGoogletag() {
        window.googletag = window.googletag || {};
        window.googletag.cmd = window.googletag.cmd || [];
        window.googletag.cmd.push(function () {
            window.googletag.pubads().disableInitialLoad();
            window.googletag.enableServices();
        });
    }

    checkGoogletagSlots() {
        window.setTimeout(() => {
            if (window.googletag?.pubads === undefined) {
                return false;
            }
            console.log(
                `googletag used slots: ${googletag.pubads().getSlots().length}`
            );
        }, 1000);
    }
}

export function loadTeads(cb) {
    if (ads.isDisabled() || !publisher_config.teads) {
        return false;
    }
    externalAdLibs.loadTeads(publisher_config.teads, cb);
}

export function loadTaboola() {
    if (ads.isDisabled() || !publisher_config.taboola) {
        return false;
    }
    externalAdLibs.loadTaboola(publisher_config.taboola);
}

export function loadSeedtag() {
    let valid_user = cookie.get('gigya_user');

    if (ads.isDisabled() || !publisher_config.seedtag || valid_user) {
        return false;
    }
    externalAdLibs.loadSeedtag(publisher_config.seedtag);
}

// TODO: load gtag.js
// if (!ads.isDisabled()) {
//     externalAdLibs.loadGtm();
// }

docReady(() => {
    //externalAdLibs.loadDidomi();

    // TODO: load external libs w/o waiting docReady event
    if (!ads.isDisabled()) {
        new Pubload()[ads_source]();
    }

    ws.listen_desktop(function () {
        dataLayer['0']['platform'] = ws.detect();
        dataLayer.push({ platform: ws.detect() });
    });
});
