'use strict';

/* Current / total slide counter + copy text below image */
function setCaptionVisible($carousel) {
    $('.carousel-caption-visible', $carousel).html($('.carousel-caption-invisible', $carousel).eq(0).html());

    $carousel.bind('slide.bs.carousel', (ev) => {

        $('.current', $carousel).text(ev.to + 1);

        let $currentSlide = $(ev.relatedTarget);
        let currentText = $('.carousel-caption-invisible', $currentSlide.prev()).html();

        $carousel.find('.carousel-caption-visible').stop().animate({'opacity': 0}, 100, function () {
            $(this).html(currentText).animate({'opacity': 1}, 50);
        });
    });
}

function setCaptionVisibleHeight($carousel, $el = false) {
    const captionHeights = [...$carousel[0]?.querySelectorAll('.carousel-caption-invisible')]
        .map((caption) => {
            caption.classList.add("img-description");
            const style = window.getComputedStyle(caption);
            const calcHeight = parseInt(style.getPropertyValue('height')) + 1;
            caption.classList.remove("img-description");
            return calcHeight;
        });

    $el = $el || $('.carousel-caption-visible');
    $el.css('min-height', Math.max(...captionHeights));
    // $el.animate({height: Math.max(...captionHeights)}, 1000);
}

export function setCarouselCaptions(carousel = '.carousel.captions') {
    const $carousel = $(carousel).eq(0);

    if (!$carousel.length) {
        return false;
    }

    setCaptionVisibleHeight($carousel);
    setCaptionVisible($carousel);
}