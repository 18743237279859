import './images.js';
import './minibar.js';
import './nav.js';
import './nav-overflow.js';
import './sky.js';
import './ads/pubload.js';
import './exports.js';

import { Gigya } from './gig.js';
import { docReady } from '../lib/docready.js';

docReady(() => {
    if (window.gigya !== undefined) {
        window.gig = new Gigya();
        window.gig.updateUserStatus();
    }
});
