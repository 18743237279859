'use strict';

export class Reset {

    resetAriaExpanded() {
        const menuTriggers = document.querySelectorAll('*[aria-expanded="true"]');

        if (menuTriggers.length) {
            Array.prototype.forEach.call(menuTriggers, (el) => {
                el.setAttribute('aria-expanded', false);
                el.classList.add('collapsed');
            });
        }
    }

    resetDropdown() {
        const subItemList = document.querySelectorAll('.collapse.show');

        if (subItemList.length) {
            Array.prototype.forEach.call(subItemList, (el) => {
                el.classList.remove('show');
            });
        }
    }

    resetMenuSelected() {
        let selected = document.getElementsByClassName('selected');

        if (selected.length) {
            selected[0].classList.remove('selected');
        }
    }

    resetNavbarSubmenu() {
        let navbar = document.querySelectorAll('.navbar-menu');

        if (navbar.length) {
            navbar[0].classList.remove('submenu');
        }
    }

    resetOpen() {
        let openList = document.getElementsByClassName('open');

        if (openList.length) {
            Array.prototype.forEach.call(openList, (el) => {
                el.classList.remove('open');
            });
        }
    }

    resetNoScroll() {
        let scrollList = document.getElementsByClassName('noscroll');

        if (scrollList.length) {
            Array.prototype.forEach.call(scrollList, (el) => {
                el.classList.remove('noscroll');
            });
        }
    }
}
