'use strict';

export class Cookie {

    set(name, value = true, expire_days, secure = true) {
        let expire_date = new Date();
        expire_date.setDate(expire_date.getDate() + expire_days);
        document.cookie = name + "=" + encodeURIComponent(value) + "; path=/" +
            ((expire_days) ? "; expires=" + expire_date.toUTCString() : "") +
            ((secure) ? "; secure" : "");
    }

    get(name) {
        let found = document.cookie
            .split('; ')
            .find(row => row.startsWith(name));
        return found ? found.replace(found.split('=', 1) + '=', '') : false;
    }

    remove(name) {
        this.set(name, "", -1);
    }

    exists(name) {
        return this.get(name).length > 0;
    }
}
