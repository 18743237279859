'use strict';

import {Overlay} from '../lib/overlay.js';
import {Reset} from '../lib/reset.js';
import {docReady} from '../lib/docready.js';


function processSubmenuData(subData) {
    $(this).next().html(subData).data('loaded', true);
}

function loadSubmenu(link) {
    let $link = $(link.target);

    if ($link.next().data('loaded')) {
        console.log('cached submenu!');
        return;
    }

    let slug = $link.data('slug');
    let menu_url = $link.data('menu-url');
    if (!menu_url)
      menu_url='/seccion/navSubmenu/seccion/' + slug;

    console.log('URL menu: ', menu_url);

    console.log(`loading '${slug}' submenu...`);
    $.get(menu_url).done(processSubmenuData.bind($link));
}

function handleMenuItems() {
    let overlay = new Overlay();
    let reset = new Reset();

    const menuTriggers = document.querySelectorAll('.nav-menu .jsdown');
    const header = document.getElementsByTagName('header');
    const navbar = document.querySelectorAll('.navbar-menu');

    if (!menuTriggers.length || !header.length) {
        return false
    }

    $(menuTriggers).on('click.bs.dropdown', loadSubmenu);

    Array.prototype.forEach.call(menuTriggers, (el) => {
        el.addEventListener('click', (event) => {
            event.preventDefault();
            reset.resetMenuSelected();
            reset.resetNavbarSubmenu();

            if (!el.nextElementSibling.classList.contains('show')) {
                navbar[0].classList.add('submenu');
                el.parentElement.classList.add('selected');
                overlay.triggerOverlay();
                reset.resetDropdown();
                reset.resetAriaExpanded();
            } else {
                window.location.href = el.href;
            }

            overlay.toggle(header[0]);
        });
    });
}

function handleHamburgerMenu() {
    const hamburgerTrigger = document.getElementById('hamburger');
    const body = document.querySelector('body');
    const navbar = document.getElementById('top-nav');
    const menu = document.getElementById('hamburger-menu');

    let overlay = new Overlay();
    let reset = new Reset();

    if (!hamburgerTrigger) {
        return false;
    }
    hamburgerTrigger.addEventListener('click', (event) => {
        event.preventDefault();

        hamburgerTrigger.classList.toggle('open');

        if (menu.classList.contains('show')) {
            reset.resetNoScroll();
            return overlay.toggle(navbar);
        }

        body.classList.add('noscroll');
        reset.resetDropdown();
        reset.resetMenuSelected();
        reset.resetAriaExpanded();
        overlay.triggerOverlay();
        overlay.toggle(navbar);
    });
}

function handleHamburgerItem() {
    let hamburgerItems = document.querySelectorAll('#hamburger-items .nav-item .btn-link');

    Array.prototype.forEach.call(hamburgerItems, (el) => {
        el.addEventListener('click', () => {
            let submenu = el.nextElementSibling;
            if (submenu.classList.contains('show')) {
                return submenu.querySelector('li a').click();
            }
        });
    });
}


docReady(() => {
    let overlay = new Overlay();

    overlay.handleOverlay();
    handleMenuItems();
    handleHamburgerMenu();
    handleHamburgerItem();
});
