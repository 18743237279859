'use strict';

import { Cookie } from '../lib/cookie.js';
import { closeModal, openModal } from '../lib/modal';
import { stripTags } from '../lib/string';

const GigyaConfig = {
    login_screenSet: 'Default-RegistrationLogin', //Screen-Set ID
    login_startScreen: 'gigya-login-screen', //First Screen ID from Screen-Set of "Default-RegistrationLogin"
    register_startScreen: 'gigya-register-screen', //First Screen ID from Screen-Set of "Default-RegistrationLogin"
    profileUpdate_screenSet: 'Default-ProfileUpdate', //Screen-Set ID
    profileUpdate_startScreen: 'gigya-update-profile-screen', //First Screen ID from Screen-Set of "Default-ProfileUpdate"
};

//Workaround mobile login con Facebook
const getScreenSetExtraParams = function () {
  console.log('Extra params:',  window.dataLayer[0].platform);
  return window.dataLayer[0].platform === 'desktop' ? {} : {
    authFlow: 'redirect',
    redirectURL: document.location.href
  }
}

function loginAction(response) {
    typeof gig === 'object' ? gig.loginAction(response) : false;
}

export function getUser() {
    return typeof gig === 'object' ? gig.user : false;
}

//Temporal eliminar despues de debugging.
export function getPaywallSubscription() {
    return typeof gig === 'object' ? gig.paywallSubscription: false;
}

export class Gigya {
    user = false;
    paywallSubscription = false;

    constructor() {
        if (window.gigya === undefined || !window.gigya.isGigya) {
            console.error('gigya library not loaded!');
            return false;
        }

        console.log('gigya init');

        this.handleGigya();
        this.handleGigyaEvents();
    }

    handleGigyaEvents() {
        $('body').on('click', '.gLogin', this.showCustomLogin.bind(this));
        $('body').on('click', '.gRegister', this.showCustomRegister.bind(this));
        $('body').on('click', '.gLogout', this.gigyaLogout);
        // $('.gProfile').click(this.showProfileUpdate);
    }

    handleGigya() {
        gigya.accounts.addEventHandlers({
            onLogin: function(e) {
              //No se por que coño 'e' no viene correctamente en UTF8. 
              //OJO solo es cuando hacermos "social login"
              loginAction({
                reg_id: e.UID,
                email: e.profile.email,
                firstName: e.profile.firstName || '',
                lastName: e.profile.lastName || '',
                profile: e.profile,
                newUser: e.newUser,
                paywallSubscription: e.data.paywall_hipatia || null
              });
            }
        });
    }

    setDataLayerValues(uid) {
        let dataLayer = (window.dataLayer = window.dataLayer || []);

        dataLayer['0']['user_id'] = uid;
        dataLayer['0']['user_logged'] = '1';
        dataLayer.push({ user_logged: 1, user_id: uid });
    }

    getSuscriptionInfo() {
       return this.checkSuscriptionInfo(this.paywallSubscription);
    }

    //Comprueba si tenemos una suscripción (retorna true) o no (retorna false). 
    checkSuscriptionInfo(paywall_hipatia) {
        const s1 = `display: inline-block; color: #fff; background: #00cd00; padding: 1px 4px; border-radius: 3px`;
        const s2 = `display: inline-block; color: #fff; background: #000000; padding: 1px 4px; border-radius: 3px`;
        const c = `display: inline;`;

        if (paywall_hipatia !== null)
        {
            console.log('%cTipo usuario:%c', s1, c, paywall_hipatia.subscriberType);
            if (paywall_hipatia.contracts !== undefined)
            {
                if (Array.isArray(paywall_hipatia.contracts) && paywall_hipatia.contracts.length > 0)
                {
                    //Ultima suscripción
                    let suscripcion =
                        paywall_hipatia.contracts[
                            paywall_hipatia.contracts.length - 1
                        ];

                    //Miramos si la ultima suscripción está vigente.
                    let fin = new Date(suscripcion.date.until);
                    let ahora = new Date();

                    if (fin > ahora)
                    {
                        console.log('%cSuscriptor%c. Hasta: ', s1, c, fin.toISOString());
                        if (suscripcion.cancelled)
                           console.log('%cRenovación cancelada%c', s2, c);
                        console.log('%cProducto:%c ', s2, c, suscripcion.product);
                        return (paywall_hipatia.subscriberType==='DIGITAL');
                    }
                    else
                        console.log('%cEx-suscritor%c. Caducó:', s2, c, fin.toISOString());
                } 
                else 
                    console.log('%cSuscripción:%c No tengo contratos', s2, c);
            } 
            else
                console.log('%cSuscripción:%c No soy suscriptor.', s2, c);
        } 
        else 
            console.log('%cSuscripción:%c No he pasado por la tienda', s2, c);

        return false;
    }

    getUserData(response) {
        return {
            reg_id: response.UID,
            email: response.profile.email,
            firstName: response.profile.firstName || '',
            lastName: response.profile.lastName || '',
            dateCreate: response.registered.split('T')[0],
            profile: response.profile,
            newUser: false,
            data: response.data,
            paywallSubscription: response.data.paywall_hipatia || null
        };
    }

    loginAction(user_data) {
        //Prometeo. Adelantamos lo más posible la config de prometeo
        if (
            typeof window._PROMETEO_MEDIA_CONFIG !== 'undefined' &&
            typeof window._PROMETEO_MEDIA_CONFIG.setClientEncript === 'function')
        {
            window._PROMETEO_MEDIA_CONFIG.setClientEncript(user_data);
        }

        // Obtener info del usuario logeado...
        const s=`display: inline-block; color: #fff; background: #cd0000; padding: 1px 4px; border-radius: 3px`;
        const s1 = `display: inline-block; color: #fff; background: #008800; padding: 1px 4px; border-radius: 3px`;
        const s2 = `display: inline-block; color: #fff; background: #000000; padding: 1px 4px; border-radius: 3px`;
        const c=`display: inline;`;
        console.log('%cLogin Action:%c', s, c, user_data);
        console.log('%cUser UID:%c', s, c, this.user);

        this.user = user_data.reg_id;
        this.paywallSubscription = user_data.paywallSubscription;
        let suscrito = this.checkSuscriptionInfo(this.paywallSubscription);
        if (suscrito)
           console.log('%cSUSCRIPCION%c', s1, c);
        else
           console.log('%cSIN SUSCRIPCION%c', s2, c);

        $('body').data('user', this.user);

        // Comprobar si existe el usuario o guardarlo
        if (!cookie.get('gigya_user'))
        {
            console.log('%cChecking User%c', s, c);
            $.get('/usuario/checkUser?uid=' + this.user, function(data) {
                console.log('%cLogging info%c', s, c, data);
            });
            cookie.set('gigya_user', this.user, 365);
        }

        // Datalayer
        this.setDataLayerValues(this.user);

        // Charbeat
        let _cbq = (window._cbq = window._cbq || []);
        if (suscrito)
           _cbq.push(['_acct', 'lgdin']);
        else
           _cbq.push(['_acct', 'paid']);

        //Marfeel.
        window.marfeel = window.marfeel || { cmd: [] };
        window.marfeel.cmd.push(['compass', function(compass) {
            if (suscrito) 
              compass.setUserType(3);
            else 
              compass.setUserType(2);

            compass.setSiteUserId(user_data.reg_id);

            if (user_data.newUser) {
                compass.trackConversion('signup');
            }
        }]);

        // Logado -> mostrar mensaje de bienvenida, datos del usuario...
        let name = user_data.profile.nickname || user_data.firstName;

        $('.logged-user-img').prop('src', user_data.profile.thumbnailURL);
        $('.gLogin>span, .logged-user').html(stripTags(name));

        $('.gLogin').unbind('click').removeClass('gLogin');

        // Si esta suscrito o no existe suscripcion en el site, mostrar el boton de salir
        if (suscrito || !this.paywallSubscription) {
            $('.navbar .btn.subscribe').html($('.navbar .btn.subscribe').data('logout-text'));
            $('.navbar .btn.subscribe').unbind('click').click(this.gigyaLogout);
        }

        $('.navbar-links').removeClass('hidden');

        //OJO AQUI. ¿Habra que desbloquear dependiendo de si somos o no suscriptores y/o registrados?
        $('.faded-paragraph').removeClass('faded-paragraph');
        $('.registered-content').show();
        $('.unregistered-content').hide();

        closeModal();
    }

    logoutAction() {
        $('body').data('user', null);
        $('.navbar-links').removeClass('hidden');
    }

    gigyaLogout(e) {
        const s=`display: inline-block; color: #fff; background: #cd0000; padding: 1px 4px; border-radius: 3px`;
        const c=`display: inline;`;

        console.log('%cGIGYA Logout%c', s, c);
        // Cuando se clique sobre "Salir" se cerrará la sesión y se mostrará el login
        e.preventDefault();

        gigya.accounts.logout();
        // document.cookie = 'gigya_user=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
        cookie.remove('gigya_user');

        //Logout symfony user. Al acabar el GET y no antes recargamos la home
        $.get('/usuario/logout').done(function() {
          window.location.href = '/'
        });
    }

    updateUserStatus() {
        console.log('Update User Status');
        gigya.accounts.getAccountInfo({
            callback: (response) =>
                response.errorCode === 0
                    ? this.loginAction(this.getUserData(response))
                    : this.logoutAction(),
        });
    }

    openCustomModal(cb = false, link_class = '', modal_class = '#base-modal') {
        $.get('/usuario/modal', function (rdata) {
            openModal(rdata);
            $(`${modal_class} .nav-link`).removeClass('active');
            $(`${modal_class} .nav-link.${link_class}`).addClass('active');
            if (typeof cb === 'function') {
                cb();
            }
        });
    }

    loadCustomLogin() {
        gigya.accounts.showScreenSet({
            "containerID": 'gigyaWrapper',
            "screenSet": GigyaConfig.login_screenSet,
            "startScreen": GigyaConfig.loginStartScreen,
            ... getScreenSetExtraParams()
            /*
            onBeforeScreenLoad: function(event) {
                console.log('beforeevent', event);
            },
            onAfterScreenLoad: function(event) {
                console.log('afterevent', event);
            },*/
        });
    }

    loadCustomRegister() {
        gigya.accounts.showScreenSet({
            "containerID": 'gigyaWrapper',
            "screenSet": GigyaConfig.login_screenSet,
            "startScreen": GigyaConfig.register_startScreen,
            ... getScreenSetExtraParams()
        });
    }

    // Custom gigya modal
    showCustomLogin(e) {
        e.preventDefault();
        this.openCustomModal(this.loadCustomLogin, 'gLogin');
    }

    // Custom gigya modal
    showCustomRegister(e) {
        e.preventDefault();
        this.openCustomModal(this.loadCustomRegister, 'gRegister');
    }

    // Native gigya modal
    showLogin(e) {
        // Cuando se clique sobre login se mostrará login_startScreen
        e.preventDefault();
        //let params = {};


        // API de Gigya que muestra la pantalla seleccionada en startScreen sobre el containerID
        // Si se desea enviar algún dato adicional del esquema
        // "data": { clave: 'valor' };
        gigya.accounts.showScreenSet({
          "startScreen": GigyaConfig.loginStartScreen,
          "screenSet": GigyaConfig.login_screenSet
        });
    }

    showRegister(e) {
        // Cuando se clique sobre registro se mostrará register_startScreen
        e.preventDefault();
        gigya.accounts.showScreenSet({
          "startScreen": GigyaConfig.register_startScreen,
          "screenSet": GigyaConfig.login_screenSet
        });
    }

    showProfileUpdate(e) {
        // Cuando se clique sobre "Modificar mis datos" se mostrarÃ¡ el screenset Default-ProfileUpdate
        e.preventDefault();
        let params = {};

        params['startScreen'] = GigyaConfig.profileUpdate_startScreen;
        params['screenSet'] = GigyaConfig.profileUpdate_screenSet;

        gigya.accounts.showScreenSet(params);
    }
}

let cookie = new Cookie();
