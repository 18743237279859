'use strict';

import { Cookie } from './cookie.js';

export class Ads {
    DisableAds = 'noads';

    constructor(ads = {}) {
        this.setAds(ads);
        this.cookie = new Cookie();
    }

    enable() {
        this.cookie.remove(this.DisableAds);
    }

    disable() {
        this.cookie.set(this.DisableAds, 1, 365);
    }

    toggle() {
        this.cookie.exists(this.DisableAds) ? this.enable() : this.disable();
    }

    isDisabled() {
        return (
            $(`meta[name=${this.DisableAds}]`).length ||
            this.cookie.exists(this.DisableAds)
        );
    }

    setAds(ads) {
        this.ads = ads;
    }

    assignClass() {
        // Remove all classes found on data attribute
        let class_data = $('.ads.assignable').data('class');

        if (!class_data) {
            console.error('No data-class attr. found');
            return false;
        }

        $('.ads.assignable').removeClass(class_data);

        $.each(this.ads, (i, content) => {
            let own_class = $(content).data('class');

            if (!own_class) {
                console.error($(content), "doesn't have data-class attr.");
                return false;
            }

            // Set classes using data-class attr
            $(content).addClass(own_class);
        });
    }

    reassignAdIds() {
        this.assignClass();
        this.refresh();
    }

    moveTop(source = '.ad-top-container:first') {
        console.log('Moving top ad');
        const target = $('.ads-top-after').first();

        if (!target.length) {
            console.warning('MoveTop: Sin target');
            return;
        }

        this.setAds($(`${source}`));
        const $current_ad = this._getClonedAd(this.ads);
        return $current_ad.insertAfter(target);
    }

    moveToPost({ source = '.ads.assignable', every_p = [2] } = {}) {
        this.setAds($(`.aside ${source}`));
        this._removeMovedAds();

        // Skip p elements with less than 15 words
        let $filtered_p = $(
            '.post-text *:not(blockquote):not(.no-ad-wrapper) > p:not(:empty)'
        ).filter((i, e) => {
            return $(e).text().split(' ').length > 15;
        });

        console.log(`Found ${$filtered_p.length} valid paragraphs`);

        const $prometeo = $('#recommend-recirculation'),
            $topnews = $('.lo-mas').parent(),
            $comments = $('.post-comments');

        $.each(this.ads, (i, ad) => {
            const $current_ad = this._getClonedAd($(ad));
            const ad_position = (every_p[i] || every_p[every_p.length - 1]) - 1;

            // If there are enough <p> elements left
            if ($filtered_p.length > 1 && $filtered_p.length > ad_position) {
                let not_last_p = $($filtered_p[ad_position]).next().length;

                if (not_last_p) {
                    return $current_ad.insertAfter($filtered_p[ad_position]);
                }
            }

            // Try to move one before prometeo widget
            if ($prometeo.length && !$prometeo.prev().hasClass('moved')) {
                console.log('Moving before recommend experience');
                return $current_ad.insertBefore($prometeo);
            }

            // Try to move one after prometeo widget
            if ($prometeo.length && !$prometeo.next().hasClass('moved')) {
                console.log('Moving after recommend experience');
                return $current_ad.insertAfter($prometeo);
            }

            // Try to move before after topnews widget
            if ($topnews.length && !$topnews.prev().hasClass('moved')) {
                return $current_ad.insertBefore($topnews);
            }

            // Try to move one after topnews widget
            if ($topnews.length && !$topnews.next().hasClass('moved')) {
                return $current_ad.insertAfter($topnews);
            }

            // Try to move one after post comments
            if ($comments.length && !$comments.next().hasClass('moved')) {
                return $current_ad.insertAfter($comments);
            }

            // Move remaining ads to bottom
            this._moveToBottom($current_ad);
        });

        this.setAds($(`${source}`).not('.original'));
        this.reassignAdIds();
    }

    moveToLive(source = '.ads.assignable') {
        this.setAds($(`.aside ${source}`));

        this._removeMovedAds();

        let target = Array.from(
            document.querySelectorAll('.directos > div:not(:empty)')
        );

        $.each(this.ads, (i, ad) => {
            let $current_ad = this._getClonedAd($(ad));

            if (target.length > i) {
                return $current_ad.insertAfter(target.shift());
            }

            // Clear filtered_p so it doesn't go back to live list
            target = false;

            // Move the remaining ads to bottom
            this._moveToBottom($current_ad);
        });

        this.setAds($(`${source}`).not('.original'));
        this.reassignAdIds();
    }

    moveToLiveSports(
        source = '.ads.assignable',
        every = 8,
        target = '#live-list > div:not(:empty)'
    ) {
        this.setAds($(`.aside ${source}`));

        this._removeMovedAds();

        $target = Array.from(document.querySelectorAll(target));

        $.each(this.ads, (i, ad) => {
            let $current_ad = this._getClonedAd($(ad));

            if (
                $target.length > i &&
                this._insertEvery($current_ad, $($target), i, every)
            ) {
                return;
            }

            // Clear filtered_p so it doesn't go back to live list
            $target = false;

            // Move the remaining ads to bottom
            this._moveToBottom($current_ad);
        });

        this.setAds($(`${source}`).not('.original'));
        this.reassignAdIds();
    }

    moveToMovie(source = '.ads.assignable') {
        this.setAds($(`.aside ${source}`));

        this._removeMovedAds();

        $.each(this.ads, (i, ad) => {
            let $current_ad = this._getClonedAd($(ad));

            // Try to move one before sinopsis
            if (
                $('.sinopsis').length &&
                !$('.sinopsis').prev().hasClass('moved')
            ) {
                return $current_ad.insertBefore($('.sinopsis'));
            }

            // Try to move one before ficha
            if ($('.ficha').length && !$('.ficha').prev().hasClass('moved')) {
                return $current_ad.insertBefore($('.ficha'));
            }

            // Try to move one after ficha
            if (
                $('.ficha').length &&
                !$('.ficha').next().next().hasClass('moved')
            ) {
                return $current_ad.insertAfter($('.ficha').next());
            }

            // Move the rest to the bottom
            this._moveToBottom($current_ad);
        });

        this.setAds($(`${source}`).not('.original'));
        this.reassignAdIds();
    }

    moveToList(every, target, source = '.ads.assignable') {
        this.setAds($(`.aside ${source}`));

        this._removeMovedAds();

        let $target = $(`${target}`);

        every = this._recalcEvery($target, every);

        $.each(this.ads, (i, ad) => {
            let $current_ad = this._getClonedAd($(ad));

            if (this._insertEvery($current_ad, $target, i, every)) {
                return;
            }

            // Try to move one after the list
            if (!$('.main-list').next().hasClass('moved')) {
                return $current_ad.insertAfter($('.main-list'));
            }

            // Move the rest to the bottom
            this._moveToBottom($current_ad);
        });

        this.setAds($(`${source}`).not('.original'));
        this.reassignAdIds();
    }

    moveToSidebar({ source = '.aside .ads.assignable' } = {}) {
        this.setAds($(source));
        this._removeMovedAds();
        this.reassignAdIds();
    }

    refresh() {
        if (
            window.googletag === undefined ||
            window.googletag.pubads === undefined
        ) {
            return false;
        }
        googletag.pubads().refresh();
    }

    _removeMovedAds() {
        $('.ads.moved').remove();
        $('.ads.original').removeClass('original');
    }

    _moveToBottom($ad, target = 'main > .container > *:last-child') {
        $ad.insertBefore($(target));
    }

    _getClonedAd($ad) {
        // console.log('Now processing '+$('.ad-content',$ad).prop('id'));
        let $cloned = $ad.clone();
        $ad.addClass('original');
        $ad.empty();
        return $cloned.addClass('moved');
    }

    _recalcEvery($target, every) {
        if ($target.length < every) {
            return Math.floor($target.length / 2);
        }
        return every;
    }

    _insertEvery($element, $target, current, every) {
        // Try to move them after 'every' target
        let target_cont = $target.eq(current * every + every - 1);

        if (target_cont.length) {
            return $element.insertAfter(target_cont);
        }

        return false;
    }
}
