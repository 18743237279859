import './modules/__base.js';
import './modules/carousel.js';
import './modules/webcam.js';

import '../scss/home.scss';

import { docReady } from './lib/docready';
import { setCarouselCaptions } from './modules/carousel';
import { WindowSize } from './lib/windowsize';
import { Ads } from './lib/ads.js';

const ws = new WindowSize();

docReady(() => {
    setCarouselCaptions();

    if (ws.is_mobile()) {
        const ads = new Ads();
        ads.moveTop();
    }
});
