'use strict';

import { docReady } from '../lib/docready.js';
import { WindowSize } from '../lib/windowsize.js';

const ws = new WindowSize();

function menuOverflowToDropdown(menuItems, maxMenuItems = 9) {
    let menuItemsOverflow = [...menuItems].slice(maxMenuItems);

    if (menuItemsOverflow.length <= 1) {
        console.log(
            `nav menu can contain ${maxMenuItems} items: not collapsing`
        );
        return false;
    }

    console.log(
        `nav menu allows ${maxMenuItems} items max, overflows by ${menuItemsOverflow.length} items: collapsing dropdown`
    );

    let dropdown = document.createElement('li');
    let menu = document.createElement('div');
    let lnk = document.createElement('a');
    let navMenu = document.querySelector('.nav-menu');

    dropdown.setAttribute('class', 'nav-item dropdown');
    menu.setAttribute('class', 'dropdown-menu');

    menuItemsOverflow.forEach((menuItem) => {
        menuItem.classList.remove('nav-item');
        menuItem.firstElementChild.setAttribute(
            'class',
            `${menuItem.classList} dropdown-item`
        );
        menu.appendChild(menuItem.firstElementChild);
        menuItem.remove();
    });

    lnk.setAttribute('class', 'dropdown-toggle');
    lnk.setAttribute('data-toggle', 'dropdown');
    lnk.textContent = navMenu.dataset.overflowLabel || 'Más...';

    dropdown.appendChild(lnk);
    dropdown.appendChild(menu);
    menuItems[maxMenuItems - 1].after(dropdown);
}

function calcMaxMenuItemsOnViewport(menuItems) {
    let menuWidth = document.querySelector('.nav-menu')?.offsetWidth;

    for (let index = menuItems.length - 1; index > 0; index--) {
        let offsetX = menuItems[index].offsetLeft;
        // console.log(offsetX,offsetX, menuItems[index], offsetX < menuWidth);
        if (offsetX < menuWidth) {
            // index++;
            console.log(
                `${index} of ${
                    menuItems.length - 1
                } menuItems fit in ${menuWidth}px`
            );
            return index;
        }
    }

    return menuItems.length;
}

docReady(() => {
    if (ws.is_desktop()) {
        const menuItems = document.querySelectorAll(
            '.nav-menu .nav-item:not(.actions)'
        );

        menuOverflowToDropdown(
            menuItems,
            calcMaxMenuItemsOnViewport(menuItems)
        );
    }
});
