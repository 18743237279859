'use strict';

export const publisher_config = {
    ultimahora: {
        //teads: '87283',
        taboola: 'ltimahoraes',
        videojs: 'gs-ultimahora',
        seedtag: '3917-9762-01',
        /*intext: [
           { "id": "intext", "class": "henneoHB henneoHB-intext", "position": 2, "mobile": true }
        ]*/
    },
    menorca: {
        //teads: '87210',
        taboola: 'menorcainfo',
        videojs: 'gs-menorcainfo',
        seedtag: '2616-6589-01',
        /*intext: [
           { "id": "intext", "class": "henneoHB henneoHB-intext", "position": 2, "mobile": true }
        ]*/
    },
    ibiza: {
        //teads: '87211',
        taboola: 'peridicodeibiza',
        videojs: 'gs-periodicodeibiza',
        seedtag: '7428-4632-01',
        /*intext: [
           { "id": "intext", "class": "henneoHB henneoHB-intext", "position": 2, "mobile": true }
        ]*/
    },
    magazin: {
        teads: '103816',
    },
    daily: {
        teads: '103817',
    }
}[SITENAME];

export const ads_source = {
    ultimahora: 'bluemedia',
    menorca: 'bluemedia',
    ibiza: 'bluemedia',
    magazin: 'yieldLove',
    daily: 'yieldLove'
}[SITENAME];
