'use strict';

import {WindowSize} from '../../lib/windowsize';

const ws = new WindowSize();

const zoneSizes = {
    'adimpact': [[300, 150], [300, 100]],
    'right': [[300, 300], [300, 600], [300, 250]],
    'sky': [[120, 1], [120, 600], [120, 601], [120, 800], [120, 801], [160, 600], [160, 600], [480, 1001]],
    'top': {
      'mobile': [[320, 50], [320, 100]],
      'desktop': [[728, 90], [970, 250], [980, 250], [980, 90], [970, 90]]
    }
}
//Formatedo de consola.
const s=`display: inline-block; color: #fff; background: #333; padding: 1px 4px; border-radius: 3px`;
const c=`display: inline;`;

export class Yieldlove {

    constructor(siteId, siteDomain, zonePrefix) {
        Yieldlove.SITE_ID = siteId;
        Yieldlove.SITE_DOMAIN = siteDomain;
        Yieldlove.ZONE_PREFIX = zonePrefix;
        Yieldlove.FOUND_ZONES = [];
        Yieldlove.LAYOUT = window.dataLayer?.find(e => !!e)?.layout;
        window.yieldlove_cmd = window.yieldlove_cmd || [];
        console.log(`%cyieldLove%c\n\tsiteId: ${Yieldlove.SITE_ID}\n\ttargeting: ${Yieldlove.LAYOUT}\n\tdomain: ${Yieldlove.SITE_DOMAIN}\n\tzonePrefix: ${Yieldlove.ZONE_PREFIX}\n`, s, c, zoneSizes);
    }

    register() {

        this.prepareMultiZone(`${Yieldlove.ZONE_PREFIX}_robapaginas`, zoneSizes.right, this.loadZone);
        this.prepareMultiZone(`${Yieldlove.ZONE_PREFIX}_adimpact`, zoneSizes.adimpact, this.loadZone);

        if (ws.is_desktop()) {
            this.prepareMultiZone(`${Yieldlove.ZONE_PREFIX}_megabanner`, zoneSizes.top.desktop, this.loadZone);
            this.loadZone('x31', `${Yieldlove.ZONE_PREFIX}_sky_left`, zoneSizes.sky);
            this.loadZone('x41', `${Yieldlove.ZONE_PREFIX}_sky_right`, zoneSizes.sky);
        }
        else 
        {
            this.prepareMultiZone(`${Yieldlove.ZONE_PREFIX}_megabanner`, zoneSizes.top.mobile, this.loadZone);
            //Test intestitial en todas las páginas
            //if (Yieldlove.LAYOUT === 'noticia')
            {
              console.log('%cYield Love%c\nInterstitial %s', s, c, `/${Yieldlove.SITE_ID}/${Yieldlove.ZONE_PREFIX}_interstitial`);
              googletag.cmd.push(function() {
                 let slot = googletag.defineOutOfPageSlot(`/${Yieldlove.SITE_ID}/${Yieldlove.ZONE_PREFIX}_interstitial`, googletag.enums.OutOfPageFormat.INTERSTITIAL);
                 if (slot) slot.addService(googletag.pubads());
                 googletag.pubads().disableInitialLoad();
                 googletag.enableServices();
                 googletag.display(slot);
              });
            }
        }

        console.log('%cYield Love%c\nFound zones: ', s, c, Yieldlove.FOUND_ZONES);
    }

    prepareMultiZone(className, zoneSize, cb) {
        [...document.querySelectorAll(`.${className}.yieldLove`)].forEach((ad, idx) => {
            ad.id = `${className}_${idx + 1}`;
            let lazyLoad = (idx >= 3);

            if (typeof cb === 'function') {
                cb(ad.id, ad.id, zoneSize, lazyLoad);
            }
        });
    }

    loadZone(containerID, zoneId, zoneSize, lazyLoad = false) {
        let e = document.getElementById(containerID);

        if (!e) {
            return false;
        }

        window.yieldlove_cmd.push(function () {
            googletag.cmd.push(function () {
                let slot = googletag.defineSlot(`/${Yieldlove.SITE_ID}/${zoneId}`, zoneSize, containerID);
                if (Yieldlove.LAYOUT) {
                    slot.setTargeting('layout', Yieldlove.LAYOUT);
                }
                slot.addService(googletag.pubads());
                googletag.display(containerID);
            });

            if (lazyLoad) {
                window.YLHH.utils.lazyLoad(containerID, function () {
                    window.YLHH.bidder.startAuction(`/${Yieldlove.SITE_ID}/${zoneId}`, undefined)
                });
            } else {
                window.YLHH.bidder.startAuction(`/${Yieldlove.SITE_ID}/${zoneId}`, undefined)
            }
        });

        Yieldlove.FOUND_ZONES.push({
            zoneId: zoneId,
            zoneSizes: zoneSize,
            lazyLoad: lazyLoad,
            e: e
        });
    }
}
