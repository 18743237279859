'use strict';
import { docReady } from '../lib/docready.js';

let lastScroll = 0;
let didScroll = false;

function toggleMinibarFixed(barElement, barHeight, $extra, $navbarMenu) {
    if ($(window).scrollTop() > barHeight) {
        return barElement.addClass('minibar-fixed');
    }
    $extra.show();
    $('.nav-menu', $navbarMenu).css('height', 'auto');
    return barElement.removeClass('minibar-fixed');
}

function setScrollerPosition() {
    let scrollPosition =
        (window.scrollY / (document.body.offsetHeight - window.innerHeight)) *
        100;
    $('.scroller-progress').css('width', scrollPosition + '%');
}

function scrollDirections(barHeight, delta, $extra, $navbarMenu) {
    let scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

    if (scrollPosition > barHeight && scrollPosition < lastScroll) {
        if (Math.abs(lastScroll - scrollPosition) <= delta) {
            return;
        }

        $extra.length
            ? $extra.stop().animate({
                height: 0,
            }, 100, () => {$extra.hide(); addFixedMenu($navbarMenu)})
            : addFixedMenu($navbarMenu);
    } else {
        $extra.css({height: "auto"}).show();
        removeFixedMenu($navbarMenu);
    }

    lastScroll = scrollPosition <= 0 ? 0 : scrollPosition;
}

function addFixedMenu($navbarMenu) {
    $('.nav-menu', $navbarMenu).css('height', 'auto');
    $navbarMenu.addClass('fixed');
}

function removeFixedMenu($navbarMenu) {
    $('.nav-menu', $navbarMenu).css('height', 0);
    $navbarMenu.removeClass('fixed');
}

function setOriginalHeaderHeight($header) {
    let specialImg = $('.head-special img');

    if (!specialImg.length) {
        return $header.css('height', $header.outerHeight());
    }
}

function handleScrollPosition() {
    window.addEventListener('scroll', () => setScrollerPosition());
}

function handleScrollEvent(ele, eleheight, $extra, $navbarMenu) {
    toggleMinibarFixed(ele, eleheight, $extra, $navbarMenu);

    window.addEventListener('scroll', () => {
        didScroll = true;
    });

    setInterval(() => {
        if (didScroll) {
            scrollDirections(eleheight, 45, $extra, $navbarMenu);
            toggleMinibarFixed(ele, eleheight, $extra, $navbarMenu);
            didScroll = false;
        }
    }, 250);
}

docReady(() => {
    const ele = $('[data-toggle="minibar-fixed"]'),
        $extra = $('.head-extra'),
        $navbarMenu = $('.navbar-menu'),
        eleheight = ele.height() + ($extra.height() / 2.5 || 0);

    setOriginalHeaderHeight($('body > header'));
    handleScrollEvent(ele, eleheight, $extra, $navbarMenu);
    handleScrollPosition();
    setScrollerPosition();
});
