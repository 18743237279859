'use strict';

import { Reset } from './reset.js';

export class Overlay {

    constructor() {
        this.overlay = document.getElementById('black-overlay');
        this.reset = new Reset();
    }

    toggle(visible_layer) {
        
        if (! this.overlay.classList.contains('overlay-visible')) {
            this.toggleVisibleLayer(visible_layer);
            return this.triggerOverlay(true); 
        }
        return this.triggerOverlay(false);
    }

    toggleVisibleLayer(visible_layer) {
        let old_visible_layer = document.getElementsByClassName('over-overlay');

        if (old_visible_layer.length) {
            Array.prototype.forEach.call(old_visible_layer, (el) => {
                el.classList.remove('over-overlay');
            });
        }

        if (visible_layer) {
            return visible_layer.classList.add('over-overlay');    
        }
    }

    triggerOverlay(status) {
      if (status && !this.overlay.classList.contains('overlay-visible')) {
        this.overlay.classList.add('overlay-visible');
      } else if (!status && this.overlay.classList.contains('overlay-visible')) {
        this.overlay.classList.remove('overlay-visible');
      }
    }

    handleOverlay() {
        if (! this.overlay) {
            return false;
        }

        this.overlay.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.overlay.classList.contains('overlay-visible')) {
                this.triggerOverlay();
                this.reset.resetAriaExpanded();
                this.reset.resetNoScroll();
                this.reset.resetDropdown();
                this.reset.resetMenuSelected();
                this.reset.resetOpen();
                this.reset.resetNavbarSubmenu();
                this.toggleVisibleLayer();
            }
        });
    }
}