let afterIntersectCb = false;
let observer;

const intersectCb = (elements) => {
    elements.forEach((element) => {
        if (!element.isIntersecting) {
            return;
        }

        const { target } = element;
        console.log('Element intersecting:', target);

        observer.unobserve(target);

        typeof afterIntersectCb === 'function' ? afterIntersectCb() : '';
    });
};

export const handleIntersect = (
    selectors, // observe selectors array
    aICb = false, // after intersect callback
    options = { rootMargin: '-50% 0% -50% 0%' } // element center
) => {
    observer = new IntersectionObserver(intersectCb, options);

    selectors.forEach((selector) => {
        const element = document.querySelector(selector);

        if (!element) {
            // console.error(`Element ${selector} not found`);
            return;
        }

        console.log(`IntersectionObserver on ${selector}`, element);

        observer.observe(element);
    });

    afterIntersectCb = aICb;
};
