'use strict';

import {docReady} from '../lib/docready.js';
import {openModal} from '../lib/modal.js'

function handleWebcamModal() {
    $('.webcam').on('click', '.open-webcam', function () {
        openModal($(this).data('src'), 'iframe');
    })
}

docReady(() => {
    handleWebcamModal();
});